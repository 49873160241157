import * as uuid from 'uuid/v4';
import { geojson } from 'gridtools/types';
import { getBBox, merge as mergeGeoJSON } from 'gridtools/utils/geojson';
import {
  DrawColor, DrawingTool as Tool, DrawProperties,
  MapDrawState, MapDrawFinished, MapDrawNotStarted, MapDrawOngoing
} from 'types';

const CANCEL_DRAWING = 'reducers/map/draw/cancel-drawing';
const BEGIN_DRAWING = 'reducers/map/draw/begin-drawing';
const CHANGE_COLOR = 'reducers/map/draw/change-color';
const CHANGE_TOOL = 'reducers/map/draw/change-tool';
const ADD_DRAWING = 'reducers/map/draw/add-drawing';
const FINISH_DRAWING = 'reducers/map/draw/finish-drawing';
const PUT_DRAWING = 'reducers/map/draw/put-drawing';
const SELECT_DRAWING = 'reducers/map/draw/select-drawing';
const DELETE_DRAWING = 'reducers/map/draw/delete-drawing';
const REPLACE_DRAWING = 'reducers/map/draw/replace-drawing';
const CHANGE_DRAWING_PROPS = 'reducers/map/draw/change-drawing-props';
const EDIT_TEXT_PROPS = 'reducers/map/draw/edit-text-props';
const CLEAR_SELECTION = 'reducers/map/draw/clear-selection';

export type CancelDrawingAction = ReturnType<typeof cancelDrawing>;
export type BeginDrawingAction = ReturnType<typeof beginDrawing>;
export type ChangeColorAction = ReturnType<typeof changeColor>;
export type ChangeToolAction = ReturnType<typeof changeTool>;
export type AddDrawingAction = ReturnType<typeof addDrawing>;
export type FinishDrawingAction = ReturnType<typeof finishDrawing>;
export type PutDrawingAction = ReturnType<typeof putDrawing>;
export type SelectDrawingAction = ReturnType<typeof selectDrawing>;
export type DeleteDrawingAction = ReturnType<typeof deleteDrawing>;
export type ReplaceDrawingAction = ReturnType<typeof replaceDrawing>;
export type ChangeDrawingPropsAction = ReturnType<typeof changeDrawingProps>;
export type EditTextPropsAction = ReturnType<typeof editTextProps>;
export type ClearSelectionAction = ReturnType<typeof clearSelection>;

export type MapDrawAction =
  | CancelDrawingAction
  | BeginDrawingAction
  | ChangeColorAction
  | ChangeToolAction
  | AddDrawingAction
  | FinishDrawingAction
  | PutDrawingAction
  | SelectDrawingAction
  | DeleteDrawingAction
  | ReplaceDrawingAction
  | ChangeDrawingPropsAction
  | EditTextPropsAction
  | ClearSelectionAction;

export function cancelDrawing() {
  return {
    type: CANCEL_DRAWING,
  } as const;
}

export function beginDrawing(tool: Tool, color: DrawColor, geometry?: null | geojson.GeoJSON<DrawProperties>) {
  return {
    color,
    geometry,
    tool,
    type: BEGIN_DRAWING,
  } as const;
}

export function changeColor(color: DrawColor) {
  return {
    color,
    type: CHANGE_COLOR,
  } as const;
}

export function changeDrawingProps(data: Partial<DrawProperties>) {
  return {
    type: CHANGE_DRAWING_PROPS,
    data
  } as const;
}

export function changeTool(tool: Tool) {
  return {
    tool,
    type: CHANGE_TOOL,
  } as const;
}

export function addDrawing(geometry: geojson.Geometry) {
  return {
    geometry,
    type: ADD_DRAWING,
  } as const;
}

export function finishDrawing() {
  return {
    type: FINISH_DRAWING,
  } as const;
}

export function putDrawing(geometry: geojson.GeoJSON<DrawProperties> | null, keepDrawing = false) {
  return { geometry, keepDrawing, type: PUT_DRAWING } as const;
}

function getGeometries(state: MapDrawState) {
  return state.state === 'not-started' ? null : state.geometries;
}

function prepareGeometries(geom: geojson.GeoJSON<DrawProperties>): geojson.FeatureCollection<DrawProperties> {
  const makeFeature = (f: geojson.Feature<DrawProperties>) => f.properties.__key ? f : {
    ...f,
    properties: { ...f.properties, __key: uuid() }
  };
  const toCollection = (g: geojson.Feature<DrawProperties>): geojson.FeatureCollection<DrawProperties> => ({
    type: 'FeatureCollection',
    bbox: g.bbox,
    crs: g.crs,
    features: [ g ]
  });

  switch (geom.type) {
    case 'FeatureCollection':
      return geom.features.every(f => !!f.properties.__key)
        ? geom // change nothing if all features already have keys
        : { ...geom, features: geom.features.map(makeFeature) };
    case 'Feature':
      return toCollection(makeFeature(geom));
    default:
      return toCollection({
        type: 'Feature',
        geometry: geom,
        properties: { __key: uuid() } as DrawProperties,
        bbox: geom.bbox,
        crs: geom.crs
      });
  }
}

export function selectDrawing(feature: geojson.Feature<DrawProperties> | null) {
  return { type: SELECT_DRAWING, feature } as const;
}

export function deleteDrawing(feature: geojson.Feature<DrawProperties>) {
  return { type: DELETE_DRAWING, feature } as const;
}

export function replaceDrawing(feature: geojson.Feature<DrawProperties>) {
  return { type: REPLACE_DRAWING, feature } as const;
}

export function editTextProps(data: DrawProperties | null) {
  return { type: EDIT_TEXT_PROPS, data } as const;
}

export function clearSelection() {
  return { type: CLEAR_SELECTION } as const;
}


function cancelDrawingReducer(state: MapDrawState, action: CancelDrawingAction): MapDrawNotStarted {
  return {
    ...state,
    tool: null,
    state: 'not-started',
    selected: null
  };
}

function beginDrawingReducer(state: MapDrawState, action: BeginDrawingAction): MapDrawOngoing {
  return {
    color: action.color,
    geometries: action.geometry ? prepareGeometries(action.geometry) : null,
    state: 'ongoing',
    tool: action.tool,
    selected: action.tool === 'select' ? state.selected : null,
    currentTextEdit: null
  };
}

function changeFeature(geom: geojson.FeatureCollection<DrawProperties>, key: string | undefined,
                       change: (f: geojson.Feature<DrawProperties>) => geojson.Feature<DrawProperties> | null) {
  if (!key) return geom;

  const features: geojson.Feature<DrawProperties>[] = [];
  let found = false;
  geom.features.forEach(f => {
    if (f.properties.__key === key) {
      found = true;
      const changed = change(f);
      if (changed) features.push(changed);
    } else {
      features.push(f);
    }
  });

  return found ? { ...geom, features } : geom;
}

function changePropsReducer(state: MapDrawState, { data }: ChangeDrawingPropsAction): MapDrawState {
  let geometries = getGeometries(state);
  if (geometries) {
    const { __key: key, ...props } = data;
    geometries = changeFeature(geometries, key,
      f => ({ ...f, properties: { ...f.properties, ...props } }));
  }

  return {
    color: state.color,
    geometries,
    state: 'ongoing',
    tool: state.tool || 'select',
    selected: null,
    currentTextEdit: null
  };
}

function changeColorReducer(state: MapDrawState, { color }: ChangeColorAction): MapDrawOngoing {
  let { selected } = state;
  let geometries = getGeometries(state);
  if (geometries && selected) {
    const key = selected.properties.__key;
    geometries = changeFeature(geometries, key,
      f => ({ ...f, properties: { ...f.properties, color } }));
    selected = geometries.features.find(f => f.properties.__key === key) || null;
  }

  return {
    color,
    geometries,
    state: 'ongoing',
    tool: state.tool || 'select',
    selected,
    currentTextEdit: null
  };
}
function replaceDrawingReducer(state: MapDrawState, action: ReplaceDrawingAction): MapDrawState {
  let { selected } = state;
  if (!selected)
    return state;

  let geometries = getGeometries(state);
  if (geometries && selected) {
    const key = selected.properties.__key;
    geometries = changeFeature(geometries, key, () => action.feature);
    selected = geometries.features.find(f => f.properties.__key === key) || null;
  }

  return {
    ...state,
    geometries,
    state: 'ongoing',
    tool: state.tool || 'select',
    selected,
  };
}

function changeToolReducer(state: MapDrawState, action: ChangeToolAction): MapDrawOngoing {
  return {
    color: state.color,
    geometries: getGeometries(state),
    state: 'ongoing',
    tool: action.tool,
    selected: action.tool === 'select' ? state.selected : null,
    currentTextEdit: null
  };
}

function getProperties(properties: DrawProperties) {
  return function(geometry: geojson.Geometry): DrawProperties {
    return { ...properties };
  }
}

function merge(g1: null | geojson.GeoJSON<DrawProperties>, g2: geojson.GeoJSON<DrawProperties>, properties: DrawProperties): geojson.GeoJSON<DrawProperties> {
  if (g1 === null) {
    return g2;
  }
  const merged = mergeGeoJSON(g1, g2, getProperties(properties));
  if (merged === null) {
    // should not happen but typescript requires that we handle the null case
    throw new Error('Failed to merge GeoJSON objects.');
  }
  merged.bbox = getBBox(merged);
  merged.crs = g1.crs === undefined ? g2.crs : g1.crs;
  return merged;
}

function addDrawingReducer(state: MapDrawState, action: AddDrawingAction): MapDrawOngoing {
  const stateGeometries = getGeometries(state);
  const color = state.color;
  const properties: DrawProperties = { color };
  if (state.tool === 'text') {
    properties.__key = uuid();
    properties.text = 'Text value';
  } else if (state.tool === 'point') {
    properties.point_symbol = 'circle';
  }
  let geometries = merge(stateGeometries, action.geometry, properties);
  if (geometries.type !== 'FeatureCollection' && geometries.type !== 'Feature') {
    geometries = {
      bbox: geometries.bbox,
      crs: geometries.crs,
      geometry: geometries,
      properties: { ...properties },
      type: 'Feature',
    };
  }

  const prepared = prepareGeometries(geometries);
  const textFeature = state.tool === 'text'
    ? prepared.features.find(f => f.properties.__key === properties.__key) || null
    : null;

  return {
    color,
    geometries: prepared,
    state: 'ongoing',
    tool: state.tool || 'select',
    selected: textFeature,
    currentTextEdit: null
  };
}

function finishDrawingReducer(state: MapDrawState, action: FinishDrawingAction): MapDrawNotStarted | MapDrawFinished {
  return state.state === 'not-started' || state.geometries === null
    ? { ...state, state: 'not-started', tool: 'select', selected: null }
    : { ...state, geometries: state.geometries, state: 'finished', tool: 'select', selected: null };
}

function putDrawingReducer(state: MapDrawState, action: PutDrawingAction) : MapDrawState {
  return action.geometry === null
    ? <MapDrawNotStarted> { ...state, state: 'not-started', selected: null }
    : action.keepDrawing
    ? <MapDrawOngoing>    { ...state, geometries: prepareGeometries(action.geometry), state: 'ongoing' }
    : <MapDrawFinished>   { ...state, geometries: prepareGeometries(action.geometry), state: 'finished' };
}

function deleteDrawingReducer(state: MapDrawState, action: DeleteDrawingAction): MapDrawState {
  let geometries = getGeometries(state);
  if (!geometries)
    return state;

  const key = action.feature.properties.__key;
  if (key && geometries)
    geometries = changeFeature(geometries, key, () => null);
  return {
    ...state,
    geometries,
    selected: null
  };
}

function selectDrawingReducer(state: MapDrawState, action: SelectDrawingAction): MapDrawState {
  return action.feature === null || state.state === 'not-started'
    ? { ...state, selected: null }
    : { ...state, selected: action.feature };
}

function editTextPropsReducer(state: MapDrawState, action: EditTextPropsAction): MapDrawState {
  return { ...state, currentTextEdit: action.data };
}

function clearSelectionReducer(state: MapDrawState): MapDrawState {
  return { ...state, selected: null };
}


export function drawReducer(state: MapDrawState, action: MapDrawAction): MapDrawState {
  switch (action.type) {
    case CANCEL_DRAWING: return cancelDrawingReducer(state, action);
    case BEGIN_DRAWING: return beginDrawingReducer(state, action);
    case CHANGE_COLOR: return changeColorReducer(state, action);
    case CHANGE_TOOL: return changeToolReducer(state, action);
    case ADD_DRAWING: return addDrawingReducer(state, action);
    case FINISH_DRAWING: return finishDrawingReducer(state, action);
    case PUT_DRAWING: return putDrawingReducer(state, action);
    case SELECT_DRAWING: return selectDrawingReducer(state, action);
    case DELETE_DRAWING: return deleteDrawingReducer(state, action);
    case REPLACE_DRAWING: return replaceDrawingReducer(state, action);
    case CHANGE_DRAWING_PROPS: return changePropsReducer(state, action);
    case EDIT_TEXT_PROPS: return editTextPropsReducer(state, action);
    case CLEAR_SELECTION: return clearSelectionReducer(state);
    default: return state;
  }
}
